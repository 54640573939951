import debounce from '../utilities/debouncer';

const IS_TOUCH = 'ontouchstart' in window;
const EVENTS = {
    POINTER_DOWN: IS_TOUCH ? 'touchstart' : 'mousedown',
    POINTER_MOVE: IS_TOUCH ? 'touchmove' : 'mousemove',
    POINTER_UP: IS_TOUCH ? 'touchend' : 'mouseup',
    POINTER_OUT: IS_TOUCH ? 'touchleave' : 'mouseout',
};

export default class {
    constructor({
        elId,
        sliderHandle,
        slideHandle,
        overlayPrevHandle,
        overlayNextHandle,
        indexHandle,
        animateClass,
    }) {
    // Elements and class variables
        const el = document.getElementById(elId);
        const slider = el.querySelector(sliderHandle);
        const slides = el.querySelectorAll(slideHandle);
        const overlayPrev = el.querySelector(overlayPrevHandle);
        const overlayNext = el.querySelector(overlayNextHandle);
        const index = el.querySelector(indexHandle);
        const dragThreshold = 100;

        // State variables
        let activeIndex = 0;
        let dragging = false;
        let dragDist = 0;
        let lastX = null;
        let offset = 0;
        let left = true;
        let maxWidth = Array.from(slides)
            .reduce((total, slide) => total + slide.offsetWidth, 0) - slider.offsetWidth;

        // Helper functions
        function enabledButtons() {
            if (activeIndex === 0) {
                overlayPrev.setAttribute('disabled', true);
            } else {
                overlayPrev.removeAttribute('disabled');
            }

            if (activeIndex === slides.length - 1) {
                overlayNext.setAttribute('disabled', true);
            } else {
                overlayNext.removeAttribute('disabled');
            }
        }
        function moveSlides(dist) {
            Array.from(slides).forEach(slide => {
                slide.style.transform = `translateX(${dist}px)`;
            });

            index.innerHTML = activeIndex + 1;

            enabledButtons();
        }

        // Event handler functions
        const handleResize = debounce(() => {
            // Update state variables
            offset = -1 * slides[activeIndex].offsetLeft;
            maxWidth = Array.from(slides)
                .reduce((total, slide) => total + slide.offsetWidth, 0) - slider.offsetWidth;

            moveSlides(offset);
        }, 500);
        function handleSame() {
            offset = -1 * slides[activeIndex].offsetLeft;

            moveSlides(offset);
        }
        function handlePrev() {
            if (activeIndex === 0) return;

            // Update state variables
            activeIndex--;
            offset = -1 * slides[activeIndex].offsetLeft;

            moveSlides(offset);
        }
        function handleNext() {
            if (activeIndex === slides.length - 1) return;

            // Update state variables
            activeIndex++;
            offset = -1 * slides[activeIndex].offsetLeft;

            moveSlides(offset);
        }
        function handlePointerMove(e) {
            e.stopPropagation();

            if (!dragging) return;

            const clientX = e.clientX || e.touches[0].clientX;
            const diff = clientX - lastX;

            if (offset + diff > 0 || maxWidth + offset + diff < 0) return;

            // Update state variables
            lastX = clientX;
            dragDist += diff;
            offset += diff;
            left = diff < 0;

            moveSlides(offset);
        }
        function handlePointerDown(e) {
            // Remove animation class for smoother dragging
            el.classList.remove(animateClass);

            // Update state variables
            dragging = true;
            lastX = e.clientX || e.touches[0].clientX;
            left = lastX - slider.getBoundingClientRect().left > slider.offsetWidth / 2;

            slider.addEventListener(EVENTS.POINTER_MOVE, handlePointerMove);
        }
        function handlePointerUp() {
            if (!dragging) return;

            el.classList.add(animateClass);

            if (Math.abs(dragDist) < dragThreshold) {
                handleSame();
            } else if (left) {
                handleNext();
            } else {
                handlePrev();
            }

            // Update state variables
            dragging = false;
            dragDist = 0;

            slider.removeEventListener(EVENTS.POINTER_MOVE, handlePointerMove);
        }

        // Add event listeners
        window.addEventListener('resize', handleResize);
        overlayPrev.addEventListener('click', handlePrev);
        overlayNext.addEventListener('click', handleNext);
        slider.addEventListener(EVENTS.POINTER_DOWN, handlePointerDown);
        slider.addEventListener(EVENTS.POINTER_UP, handlePointerUp);
        slider.addEventListener(EVENTS.POINTER_OUT, handlePointerUp);

        // Initialize
        enabledButtons();
    }
}
