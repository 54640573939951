import './polyfills';

// Components
import ClientsCarousel from './components/clients-carousel';
import ExpandingVideo from './components/expanding-video';
import FeaturedIndustries from './components/featured-industries';
import FilterablePosts from './components/filterable-posts';
import Footer from './components/footer';
import Form from './components/form';
import Header from './components/header';
import Hero from './components/hero';
import Input from './components/input';
import Link from './components/link';
import Loader from './components/loader';
import Main from './components/main';
import Modal from './components/modal';
import PhotoGallery from './components/photo-gallery';
import PostContainer from './components/post-container';
import PostHeading from './components/post-heading';
import PostTopbar from './components/post-topbar';
import ScrollContainer from './components/scroll-container';
import SecondaryNav from './components/secondary-nav';
import Sidebar from './components/sidebar';
import Slider from './components/slider';
import Solutions from './components/solutions';
import Video from './components/video';
import VideoPlayer from './components/video-player';

// Utilities
import { instantiate } from './utilities/components';

/* eslint-disable quote-props */
const classMap = {
    clientsCarousel: ClientsCarousel,
    expandingVideo: ExpandingVideo,
    featuredIndustries: FeaturedIndustries,
    filterablePosts: FilterablePosts,
    footer: Footer,
    form: Form,
    header: Header,
    hero: Hero,
    input: Input,
    link: Link,
    loader: Loader,
    main: Main,
    modal: Modal,
    photoGallery: PhotoGallery,
    postContainer: PostContainer,
    postHeading: PostHeading,
    postTopbar: PostTopbar,
    scrollContainer: ScrollContainer,
    secondaryNav: SecondaryNav,
    sidebar: Sidebar,
    slider: Slider,
    solutions: Solutions,
    video: Video,
    videoPlayer: VideoPlayer,
};

// Event handler functions
function handleDOMConentLoaded() {
    // Call component constructors
    instantiate(document.body, classMap);
}

// Add event listeners
document.addEventListener('DOMContentLoaded', handleDOMConentLoaded);
